import { CONST } from 'Util/util';
import { validateForm, validateField } from 'app_vodafone_ziggo/util/validation';
import Modal from 'bootstrap/js/src/modal';
import VZSpinner from 'Components/VZSpinner';
import { appendCsrfToUrl } from 'app_vodafone_ziggo/util/urlUtils';

window.bs = window.bs || {};
window.bs.Modal = Modal;

const spinner = document.querySelector('vz-spinner') || new VZSpinner();

const selectors = {
    errorModal: 'error-modal',
    activeModal: '.modal.show',
    modalStepContainer: '.js-modal-step-container',
    modalStep: '.js-modal-step',
    modalStepInputs: '.form-control, .custom-control'
};

/**
 * @function showErrorModal
 * @param {string|null} title - The title of the modal
 * @param {string|null} body - The error message within the modal
 * @param {string|null} cta - The cta text within the modal
 */
function showErrorModal(title, body, cta) {
    const errorModalHtml = `
    <div class="modal" id="${selectors.errorModal}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">${title}</h3>
                    <button type="button" class="btn btn-close position-absolute top-0 end-0 mt-0 me-0" data-bs-dismiss="modal" aria-label="Sluiten"></button>
                </div>

                <div class="modal-body">
                    <div class="row justify-content-center">
                        <div class="col-12">${body}</div>
                    </div>
                </div>

                
                <div class="modal-footer">
                    <button type="button" class="btn btn-tertiary" data-bs-dismiss="modal" aria-label="Sluiten">${cta}</button>
                </div>
            </div>
        </div>
    </div>`;

    const existingModal = document.getElementById(selectors.errorModal);

    if (existingModal) {
        window.bs.Modal.getInstance(existingModal).show();
    } else {
        document.body.insertAdjacentHTML('beforeend', errorModalHtml);
        const modal = new window.bs.Modal(document.getElementById(selectors.errorModal));
        modal.show();
        window.addEventListener(CONST.events.popstate, () => {
            modal.hide();
        });
    }
}

/**
 * @public resetModalState
 * @param {HTMLElement} modal - A DOM modal element
 *
 * In case of a modal that has multiple steps
 * Loop over the steps to remove the active class
 * and add the active class to the first step
 */
function resetModalState(modal) {
    [...modal.querySelectorAll(selectors.modalStep)].forEach((step, index) => {
        if (index === 0) {
            step.classList.add(CONST.classes.active);
        } else {
            step.classList.remove(CONST.classes.active);
        }
    });
}

/**
 * @public createModalByUrl
 * @param {string} targetId - The id of the target modal
 * @param {string} targetEndpoint - The url to call which should return the modal html
 * @returns {Promise} - Promise
 *
 * @description
 * Will create/show a modal based on a URL to a pipeline.
 * That pipeline should return a template with the modal HTML for more creative freedom
 */
function createModalByUrl(targetId, targetEndpoint) {
    const modalId = `modal-${targetId}`;
    const existingModal = document.getElementById(modalId);

    if (existingModal) {
        return new Promise((resolve) => {
            resetModalState(existingModal);
            window.bs.Modal.getInstance(existingModal).show();
            resolve(existingModal);
        });
    }

    spinner.start();
    return fetch(targetEndpoint)
        .then((res) => {
            if (res.status === 500) throw new Error('Fetch failed');
            return res.text();
        })
        .then((response) => {
            document.body.insertAdjacentHTML('beforeend', response);

            const modalElement = document.getElementById(modalId);
            const modal = new window.bs.Modal(modalElement);

            new ModalClass(modal); // eslint-disable-line
            modal.show();
            document.dispatchEvent(new CustomEvent('custom_modal:shown'));
            return modalElement;
        })
        .catch((response) => {
            console.error('error', response);
            return response;
        })
        .finally(spinner.stop);
}

/**
 * @class ModalClass
 */
class ModalClass {
    constructor(modalInstance) {
        this.modal = modalInstance._element; // eslint-disable-line no-underscore-dangle
        this.modalActiveStep = this.modal.querySelector(`${selectors.modalStep}.${CONST.classes.active}`);
        this.modalFields = [...this.modal.querySelectorAll(selectors.modalStepInputs)];

        this.validateModalField = this.validateModalField.bind(this);
        this.submitStepForm = this.submitStepForm.bind(this);
        this.appendModalStep = this.appendModalStep.bind(this);

        this.modalFields.forEach((field) => {
            field.addEventListener(CONST.events.blur, (event) => {
                this.lastFocussedField = event.target;
            });

            field.addEventListener(CONST.events.keyUp, this.validateModalField);
            field.addEventListener(CONST.events.change, this.validateModalField);
        });

        this.modalActiveStep.addEventListener(CONST.events.submit, this.submitStepForm);

        const customEvent = new CustomEvent('modalLoaded', {
            detail: {
                modal: this.modal
            }
        });
        document.dispatchEvent(customEvent);
    }

    /**
     * @method validateModalField
     * @param {event} event - The submit event
     */
    validateModalField(event) {
        // If we're using tab, make sure to validate the previous field instead of the new field
        if (this.lastFocussedField && event.key && event.key.toLowerCase() === 'tab') {
            validateField(this.lastFocussedField);
            this.lastFocussedField = event.target;
        } else {
            const currField = event.target;
            this.lastFocussedField = currField;
            validateField(currField);
        }
    }

    /**
     * @method submitStepForm
     * @param {event} event - The submit event
     */
    submitStepForm(event) {
        event.preventDefault();
        const form = event.target;

        const validForm = validateForm(form);
        if (!validForm) {
            return;
        }

        const endpoint = form.getAttribute('action');
        const formData = new FormData(form);
        const csrfName = form && form.getAttribute(CONST.attributes.csrfName);
        const csrfToken = form && form.getAttribute(CONST.attributes.csrfToken);
        formData.append(csrfName, csrfToken);

        /* eslint-disable no-underscore-dangle */
        if (window._dd && window._dd.user && window._dd.user.ids && window._dd.user.ids.ga_id) {
            formData.append('gaId', window._dd.user.ids.ga_id);
        }
        /* eslint-enable no-underscore-dangle */

        spinner.start();

        fetch(endpoint, {
            method: 'POST',
            body: formData
        })
            .then((res) => res.text())
            .then(this.appendModalStep)
            .catch((error) => {
                console.error('submitStepForm error', error);
            })
            .finally(spinner.stop);
    }

    /**
     * @method scrollToTop
     */
    static scrollToTop() {
        const activeModal = document.querySelector(selectors.activeModal);
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
        if (supportsNativeSmoothScroll) {
            activeModal.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            activeModal.scrollTop = 0;
        }
    }

    /**
     * @method appendModalStep
     * @param {string} response - The response containing the next step
     */
    appendModalStep(response) {
        const modalStepContainer = document.querySelector(`${selectors.activeModal} ${selectors.modalStepContainer}`);
        const currentStep = modalStepContainer.querySelector(`${selectors.modalStep}.${CONST.classes.active}`);

        currentStep.classList.remove(CONST.classes.active);
        modalStepContainer.insertAdjacentHTML('beforeend', response);

        const newStep = modalStepContainer.querySelector(`${selectors.modalStep}:last-child`);
        newStep.classList.add(CONST.classes.active);

        this.constructor.scrollToTop();

        const customEvent = new CustomEvent('modalStep', {
            detail: {
                currentStep: currentStep,
                newStep: newStep
            }
        });
        document.dispatchEvent(customEvent);
    }
}

/**
 * @function refreshToken
 * @description Calls an endpoint to execute authorization when the Business Scan contact me modals are opened - BO-1488.
 */
function refreshToken() {
    const container = document.querySelector('[data-refresh-token-url]');
    const { refreshTokenUrl } = container.dataset;
    if (!refreshTokenUrl) return;

    const endpoint = appendCsrfToUrl(refreshTokenUrl);

    fetch(endpoint);
}

export { showErrorModal, createModalByUrl, refreshToken };
