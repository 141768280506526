'use strict';

import Lottie from 'lottie-web/build/player/lottie_light.min';
const redSpinnerData = require('./spinners/spinner_animation_min.json');

/**
 * @class VZSpinner
 * @param {HTMLElement} [parent] - Parent element for the spinner to be added in.
 * @description
 * Creates a VZ-Spinner component
 *
 * @example
 * <vz-spinner>
 *   <div class="veil">
 *     <div class="spinner"></div>
 *   </div>
 * </vz-spinner>
 */
class VZSpinner extends HTMLElement {
    constructor(parentEl) {
        super();

        this.showing = false;
        this.stop = this.stop.bind(this);
        this.veil = null;

        const parentElement = parentEl || document.body;
        const location = parentElement.tagName === 'IMG' ? parentElement.parentElement : parentElement;
        location.appendChild(this);

        this.lottie = Lottie.loadAnimation({
            container: this.veil.querySelector('.spinner'),
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: redSpinnerData
        });
    }

    /**
     * @public
     * @param {string} [msg] - A message to be shown below the spinner.
     * @returns {Promise} promise
     */
    start(msg) {
        return new Promise((resolve) => {
            if (this.showing) resolve();
            const parentPosition = window.getComputedStyle(this.parentElement).getPropertyValue('position');

            if (parentPosition === 'static') {
                if (this.parentElement.tagName !== 'BODY') {
                    this.parentElement.style.position = 'relative';
                }
                this.parentElement.classList.add('veiled');
            }

            if (msg) {
                this.veil.insertAdjacentHTML('beforeend', `<div class="content">${msg}</div>`);
            }

            this.showing = true;
            setTimeout(() => {
                if (this.showing) {
                    this.lottie?.play();
                    this.veil.classList.add('show');
                }
                resolve();
            }, 500);
        });
    }

    /**
     * @public
     * @returns {Promise} promise
     */
    stop() {
        return new Promise((resolve) => {
            if (!this.showing) resolve();
            if (this.parentElement.classList.contains('veiled')) {
                this.parentElement.style.position = '';
                this.parentElement.classList.remove('veiled');
            }

            const content = this.veil.querySelector('.content');
            if (content) content.remove();
            this.veil.classList.remove('show');
            this.showing = false;
            this.lottie?.stop();
            resolve();
        });
    }

    connectedCallback() {
        this.innerHTML = `
            <div class="veil">
                <div class="underlay"></div>
                <div class="spinner"></div>
            </div>
        `;

        this.veil = this.querySelector('.veil');
        this.veil.addEventListener('click', (evt) => evt.stopPropagation());
    }
}

window.VZSpinner = window.customElements.get('vz-spinner') || VZSpinner;
export default window.VZSpinner;

if (!window.customElements.get('vz-spinner')) {
    window.customElements.define('vz-spinner', VZSpinner);
}
