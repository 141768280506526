'use strict';

import VZSpinner from 'Components/VZSpinner';
import { appendParamToURL } from 'Root/util/urlUtils';
import { getJourney, updateJourney, clearJourney } from '../../journey/journeyHelper';

class VZDeleteGroup extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.addEventListener('click', this.onClick);
        this.deleteBtn = this.querySelector('a');
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.onClick);
    }

    /**
     * @function removeDeletedGroup
     * @description removes the deleted group from the DOM after it has been removed from the basket and dispatches an
     * event to update the monthly total
     */
    removeDeletedGroup() {
        const isMiniCart = !!this.deleteBtn.closest('vz-drawer');
        window.location = isMiniCart ? appendParamToURL(window.location.href, 'showMinicart', 'true') : window.location.href;
    }

    onClick(evt) {
        const spinner = document.querySelector('vz-spinner') || new VZSpinner();
        spinner.start();
        evt.preventDefault();

        const removeFromJourneyEmptyProduct = this.deleteBtn.getAttribute('data-empty-journey-remove');
        const removeFromJourneyExistingProduct = this.deleteBtn.getAttribute('data-journey-remove');

        if (removeFromJourneyEmptyProduct || removeFromJourneyExistingProduct) {
            let journey = getJourney();
            if (journey.length > 0) {
                journey = journey.filter((item) => item !== removeFromJourneyEmptyProduct && item !== removeFromJourneyExistingProduct);
                if (journey.every((id) => id === 'one-net') || removeFromJourneyExistingProduct === 'one-net') {
                    clearJourney();
                } else {
                    updateJourney(journey);
                }
                if (removeFromJourneyEmptyProduct) {
                    spinner.stop();
                    window.location.reload();
                    return;
                }
            }
        }

        fetch(this.deleteBtn.href, {
            method: this.deleteBtn.getAttribute('data-type') || 'GET'
        })
            .then(this.removeDeletedGroup.bind(this))
            .finally(spinner.stop);
    }
}

export default window.customElements.get('vz-delete-group') || VZDeleteGroup;

if (!window.customElements.get('vz-delete-group')) {
    window.customElements.define('vz-delete-group', VZDeleteGroup);
}
