/**
 * @class VZTracker
 * @attribute {String} data-component-id - The component identifier.
 * @description
 * Send (bubbled) click events on Anchors and Buttons to Google Analytics
 * Relying on customerGroups Object available on the Window Object. This Object can't be added to the component due to caching.
 */
export default class VZTracker extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.customerGroups = JSON.parse(window.customerGroups || '[]');
        if (this.customerGroups.length > 0) {
            this.addEventListener('click', this.onClick);
        }
    }

    disconnectedCallback() {
        this.removeEventListener('click', this.onClick);
    }

    onClick(evt) {
        if (window.ga && ['A', 'BUTTON'].includes(evt.target.nodeName)) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'targeting',
                    eventCategory: 'targeting',
                    eventLabel: `${this.dataset.componentId}-${this.customerGroups.join('-')}'`,
                    eventAction: 'click'
                });
            }
        }
    }
}

if (!window.customElements.get('vz-tracker')) {
    window.customElements.define('vz-tracker', VZTracker);
}
