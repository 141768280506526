'use strict';

import 'Components/VZDynamicLink';
import 'Components/VZTracker';
import 'Root/product/components/VZDeleteGroup';
import { createModalByUrl, refreshToken } from 'Root/modal';
import { isMobile } from 'Util/util';

class PageDesigner {
    constructor() {
        this.assignListeners.call(this);

        ['bscan:step', 'pdp:update', 'availability:update'].forEach((event) => {
            document.addEventListener(event, this.assignListeners.bind(this));
        });

        document.addEventListener('show.bs.offcanvas', this.assignListeners.bind(this));

        this.constructor.openNewTabFromParams();
    }

    assignListeners() {
        [...document.querySelectorAll('[href^="modal:"]')].forEach((link) => {
            link.addEventListener('click', this.constructor.getModal);
        });

        [...document.querySelectorAll('[href^="action:call"]')].forEach((link) => {
            link.addEventListener('click', this.constructor.initCallMe);
        });

        [...document.querySelectorAll('[href^="action:chat"]')].forEach((link) => {
            link.addEventListener('click', this.constructor.initChatWithMe);
        });
    }

    static getModal(event) {
        event.preventDefault();
        const link = event.target;
        const linkTarget = link.getAttribute('href').replace('modal:', '');
        const elementWithData = document.querySelector('.js-url-modals-data');
        const modalsData = elementWithData && JSON.parse(elementWithData.getAttribute('data-modals-data'));

        if (modalsData) {
            createModalByUrl(linkTarget, modalsData[linkTarget]).then(refreshToken);
        }
    }

    static initCallMe(event) {
        event.preventDefault();
        if (isMobile() && document.querySelector('.js-direct-call')?.href) {
            window.location.href = document.querySelector('.js-direct-call').href;
            return;
        }

        // If no BS prompt is available, check to see if we have a modal version
        const modalElement = document.querySelector('#modal-call-me');
        if (!modalElement) return;

        // Get either the existing instance or create a new one
        const modalInstance = window.bs.Modal.getInstance(modalElement) || new window.bs.Modal(modalElement);

        if (modalInstance) {
            modalInstance.show();
        }
    }

    static initChatWithMe(event) {
        event.preventDefault();
        const chatButton = document.querySelector('.dimelo_chat_item_markup .btn');

        if (chatButton) {
            chatButton.click();
        }
    }

    static openNewTabFromParams() {
        const queryString = window.location.search;
        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.has('with')) {
                const urlToOpen = urlParams.get('with');
                if (urlToOpen.startsWith('http://') || urlToOpen.startsWith('https://')) {
                    window.open(urlToOpen, '_blank');
                }
            }
        }
    }
}

new PageDesigner(); // eslint-disable-line
