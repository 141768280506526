'use strict';

import { CONST } from 'Util/util';
import { getFieldValidationMessage } from 'Components/form/form';

const validationAttributes = ['valueMissing', 'patternMismatch', 'tooShort'];

/**
 * toggleValidityClass
 * @param {boolean} [isValid] - valid or not - true or false
 * @param {HTMLElement} [target] - target element
 */
function toggleValidityClass(isValid, target) {
    if (isValid) {
        target.classList.remove('invalid');
        target.classList.add('valid');
    } else {
        target.classList.add('invalid');
        target.classList.remove('valid');
    }
}

/**
 * removeValidationMessageElement
 * @param {HTMLElement} [target] - target element
 */
function removeValidationMessageElement(target) {
    const element = target.parentElement.querySelector('.error-msg');
    if (element) element.remove();
}

/**
 * createValidationMessageElement
 * @param {string} [message] - validation message
 * @param {HTMLElement} [target] - target element
 */
function createValidationMessageElement(message, target) {
    const element =
        target.parentElement.querySelector('.error-msg') ||
        (function createErrorElement() {
            const el = document.createElement('small');
            el.classList.add('error-msg', 'text-primary');
            return el;
        })();
    element.innerText = message;
    if (target.dataset.selectedId && target.dataset.selectedId.includes('verblijfsdocument')) element.classList.add('d-inline-flex');
    target.parentElement.insertAdjacentElement('beforeend', element);
}

/**
 * @public validateField
 * @param {HTMLFormElement} field - The form field
 * @return {boolean} valid - Whether the field is valid or not
 */
function validateField(field) {
    let validField = true;

    validationAttributes.forEach((attribute) => {
        if (field.validity && field.validity[attribute]) {
            const errorMessage = field.getAttribute(CONST.attributes.validation[attribute]);
            validField = false;
            createValidationMessageElement(errorMessage, field);
            toggleValidityClass(false, field);
        }
    });

    if (validField) {
        removeValidationMessageElement(field);
        toggleValidityClass(true, field);
    }

    return validField;
}

/**
 * @public validateForm
 * @param {HTMLForm} form - The form
 * @return {boolean} valid - Whether the form is valid or not
 */
function validateForm(form) {
    let validForm = true;
    const formFields = [...form.querySelectorAll(CONST.selectors.form.input), ...form.querySelectorAll(CONST.selectors.form.textarea)];
    const dropdowns = [...form.querySelectorAll('.js-custom-select-validation')];

    formFields.forEach((field) => {
        if (field.type === 'hidden') {
            return;
        }

        const validField = validateField(field);

        if (!validField) {
            validForm = false;
        }
    });

    dropdowns.forEach((dropdown) => {
        const validField = dropdown.checkValidity();

        if (!validField) {
            validForm = false;
        }
    });

    return validForm;
}

/**
 * @private validate
 * @param {Event} evt - blur or invalid event
 * @param {string} [msg] - validation message
 */
function validate(evt, msg) {
    const message = msg || getFieldValidationMessage(evt.target);
    if (message) {
        createValidationMessageElement(message, evt.target);
        toggleValidityClass(false, evt.target);
    } else {
        removeValidationMessageElement(evt.target);
        toggleValidityClass(true, evt.target);
    }
}

export { validateForm, validateField, validate, removeValidationMessageElement, toggleValidityClass };
