'use strict';

import { setCookie, getCookie } from 'Util/cookies';

/**
 * @function getJourney
 * @description Retrieves the current journey a customer is in.
 * @returns {Array<string>} An Array of products to be configured in the current journey.
 */
function getJourney() {
    return (
        getCookie('journey')
            ?.split('|')
            .filter((item) => item.length > 0) || []
    );
}

/**
 * @function notifyJourneyModal
 * @param {Array<string>} journey - A stringified version of the journey Object
 * @description Notifies the journey modal on the new journey state
 */
function notifyJourneyModal(journey) {
    const journeyModal = document.getElementById('journey-modal');
    if (journeyModal) {
        journeyModal.setAttribute('products', journey.join('|'));
    }
}

/**
 * @function updateJourney
 * @param {Array<string>} journey - The updated journey
 * @param {Object} urls - The urls for adding journey products to basket
 * @description Updates the journey with newly added or removed products.
 */
function updateJourney(journey, urls) {
    // cookie lifetime is similar to basket lifetime (4h)
    setCookie('journey', journey.join('|'), window.location.hostname, 0.1667);
    if (urls) {
        setCookie('journeyUpdateUrl-business-mobile', urls['business-mobile'], window.location.hostname, 0.1667);
        setCookie('journeyUpdateUrl-internet', urls.internet, window.location.hostname, 0.1667);
    }
}

/**
 * @function clearJourney
 * @description Clears the journey
 */
function clearJourney() {
    setCookie('journey', null, window.location.hostname, 0);
    setCookie('journeyUpdateUrl-business-mobile', null, window.location.hostname, 0);
    setCookie('journeyUpdateUrl-internet', null, window.location.hostname, 0);
}

export { clearJourney, getJourney, notifyJourneyModal, updateJourney };
