/**
 * @export
 * @class VZDynamicLink
 * @extends {HTMLAnchorElement}
 *
 * @description
 * Can be used as an anchor. When data-url is adjusted, the href will be updated.
 */
export default class VZDynamicLink extends HTMLAnchorElement {
    constructor() {
        super();
    }

    static get observedAttributes() {
        return ['data-url'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'data-url' && oldValue !== newValue) {
            this.setAttribute('href', newValue);
        }
    }
}

if (!window.customElements.get('vz-dynamic-link')) {
    window.customElements.define('vz-dynamic-link', VZDynamicLink, { extends: 'a' });
}
